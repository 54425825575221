@import "../../styles/_variables.scss";

.overlay-modal{
  position: fixed;
  z-index: 999;
  top:0;
  // height: 100%;
  // width: 100%;
}

.modal{
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 1000;
  bottom: 0;
  max-width: 530px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  @media screen and (min-width: $sm-screen) {
    top: 0;
  }
}
