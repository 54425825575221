@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.agenciesList{

  .row{
    cursor: pointer;
    padding: $margin-xs 0;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    @include remove-tap-highlight();

    @media screen and (min-width: $sm-screen) {
      padding: 0;
    }

    &:first-child {
      @media screen and (max-width: $sm-screen) {
        display: none;
      }
    }

    > div {
      flex: 1;
      padding: 0 $margin-xs;
      @media screen and (min-width: $sm-screen) {
        max-width: 175px;
        padding: $margin-xs $margin-xs;
        &:last-child{
          white-space: nowrap;
        }
      }
      p{
        font-family: 'Manrope-SemiBold';
        font-size: $regular;
        margin: 0;
      }
      &:first-child {
        p {
          font-family: 'Manrope-ExtraBold';
        }
        @media screen and (min-width: $sm-screen) {
          p {
            font-family: 'Manrope-SemiBold';
          }
        }
      }

    }
  
    .cellCount{
      display: flex;
      align-items: center;
      background-color: $white;
      border-radius: 5px;
      padding: 2px;

      p{
        color: $text-color;
        margin: 3px 6px;
      }
  
      img{
        height: 18px;
        margin-left: auto;
        margin-right: 5px;
      }

      @media screen and (min-width: $sm-screen) {
        background-color: $light-grey;
        p{
          margin: 6px;
        }
      }
  
    }
  
  }

  @media screen and (max-width: $sm-screen) {
    .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-auto-flow: column;

      div:nth-child(3),
      div:nth-child(4) {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

}