@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.membersList {
  margin: $margin-xs 0;

  @media screen and (min-width: $sm-screen) {
    margin: 0;
  }

  .row {
    cursor: pointer;
    padding: $margin-xs 0;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    @include remove-tap-highlight();

    @media screen and (min-width: $sm-screen) {
      padding: 0;
    }
    &:first-child {
      @media screen and (max-width: $sm-screen) {
        display: none;
      }
    }

    > div {
      flex: 1;
      padding: 0 $margin-xs;
      @media screen and (min-width: $sm-screen) {
        padding: $margin-xs $margin-xs;
        max-width: 175px;
        &:last-child{
          max-width: 145px;
          white-space: nowrap;
        }
      }
      p{
        font-family: 'Manrope-SemiBold';
        font-size: $regular;
        margin: 0;
      }
    }
  
    .role {
      padding: 3px $margin-xs;
      border-radius: $radius;
      p {
        font-family: "Manrope-SemiBold";
        margin: 0;
        text-align: center;
      }
      &.Member {
        p { color: $text-color; }
        background-color: $light-grey;
      }
      &.Owner {
        background-color: $primary-color;
        p { color: $white; }
      }
      &.Admin {
        background-color: $warn;
        p { color: $white; }
      }
    }

    .firstName{
      display: none;
      height: 0px;
      @media screen and (min-width: $sm-screen) {
        height: auto;
        display: block !important;
      }
    }

    .lastName{
      span{
        display: inline-block;
      }
      @media screen and (min-width: $sm-screen) {
        span{
          display: none;
        }
      }
    }

    .agencies {
      span {
        @media screen and (min-width: $sm-screen) {
          display: block;
        }
      }
    }

    .agencies,
    .job{
      label{
        display: inline-block;
        width: 65px;
        font-family: 'Manrope-Regular';
      }
      @media screen and (min-width: $sm-screen) {
        label{
          display: none;
        }
      }
    }

  }

  @media screen and (max-width: $sm-screen) {
    .row {
      display: grid;
      grid-template-columns: 1fr 140px;
      grid-template-rows: repeat(4, auto);
      grid-auto-flow: column;
      p{
        font-family: 'Manrope-Regular';
      }

      .lastName {
        p{
          font-family: 'Manrope-ExtraBold';
          >span{
            font-family: 'Manrope-SemiBold';
          }
        }
  
      }

      >div div:not(.role) p{
        line-height: 30px;
      }

      >div:last-child {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        text-align: center;
        .role{
          max-width: 145px;
        }
      }
    }
  }
  
}