@mixin container {
  margin: auto;
	width: 100%;
	max-width: 960px;
	padding: $margin-md;
	margin: 0 auto;
}

@mixin tag($style: '') {
  font-family: 'Manrope-SemiBold';
  display: inline-block;
  color : white;
  font-size: $regular;
  background-color: rgba(106, 161, 135, 0.3);
  padding: 6px $margin-xs;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  &:not(:last-child){
    margin-right: 5px;
  }

  @if $style == primary {
    background-color: $primary-color;
  }@if $style == light-primary {
    background-color: $primary-color;
    background-color: rgba(106, 161, 135, 0.3);
  } @else if $style == warn {
    background-color: $warn;
  } @else if $style == basic {
    color : $text-color;
    background-color: $light-grey;
  }


  &.selected{
    background-color: $primary-color;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

@mixin remove-highlight(){
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight(){
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
