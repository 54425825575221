@import '../../../styles/variables.scss';

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cell{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.selected{
      .asc svg:first-child, .desc svg:last-child{
        color : $text-color;
      }
    }

    p {
      color : $text-light;
      font-size: $small;
    }
    .sort{
      position: relative;
      display: flex;
      max-height: 20px;
      width: 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      >svg{
        position: absolute;
        color : $text-light;

        &:first-child{
          transform: translateY(-4px);
        }
        &:last-child{
          transform: translateY(4px);
        }
      }
    }
  }
}