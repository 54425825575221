@import '../../../../styles/_variables.scss';

.timepicker{
  position: relative;

  >p{
    margin: 0;
    cursor: pointer;
    caret-color: transparent;
    background-color: $light-grey;
    border-radius: $radius;
    padding: 7px $margin-xs;
    .placeholder {
      color: $text-light;
      opacity: 0.5;
    }
  }

  .dropdown {
    position: absolute;
    top: calc(100% + 5px);
    z-index: 1000;
    background-color: $white;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border: 1px solid rgba(113, 122, 151, 0.21);
    padding: 5px;
    border-radius: 5px;

    .options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
      .hour {
        font-size: $small;
        margin: 0;
        padding: 2px 3px;
        cursor: pointer;
        &.selected {
          font-family: 'Manrope-SemiBold';
          background-color: rgba(106, 161, 135, 0.15);
          border-radius: 3px;
        }
        &.disabled {
          opacity: .3;
          background-color: transparent;
          pointer-events: none;
        }
      }
    }
  }
}