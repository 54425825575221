@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.container {
  position: relative;
  width: 100%;
  background-color: $white;
  border-radius: 8px;
  padding: $margin-md;
  .close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 28px;
    border-radius: 50%;
    color: $white;
    background-color: $secondary-color;
    svg {
      transform-origin: center;
      transform: rotate(-45deg);
    }
  }
  h3 {
    font-family: "Manrope-Bold";
    font-weight: normal;
    margin: 0 0 $margin-md;
    text-align: center;
  }

  .permissions {
    padding: 0;
    margin: 0 0 $margin-sm 0;
    width: 100%;

    ul {
      padding: 0;
      margin: 0 0 $margin-sm 0;
      width: 100%;
    }
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      list-style: none;
      p {
        font-size: $small;
        margin: 0;
      }

      &.permission {
        padding: 0px $margin-xs 0px $margin-xs;
        border-radius: $radius;
        overflow: hidden;
        transition: all .2s ease-in-out;

        &.selected{
          background-color: $primary-color;
          .header >p, .header svg{
            color: $white;
          }
        }
        &:not(.selected){
          background-color: $light-grey;
          .header >p, .header svg{
            color: $text-color;
          }
        }

        &:not(:last-child){
          margin-bottom: $margin-xs;
        }

        .header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          cursor: pointer;
          @include remove-tap-highlight;
          >p{
            font-family: 'Manrope-Bold';
            color: $white;
          }

          svg{
            position: relative;
            top: 2px;
            left: 3px;
            font-size: 28px;
            line-height: 16px;
          }

        }
        &.opened .rights{
          height: 176px;
          margin: 0 0 $margin-xs 0;
        }
        &:not(.opened) .rights{
          height: 0px;
        }
        .rights{
          padding: 0;
          margin: 0;
          overflow: hidden;
          transition: all .2s ease-in-out;
        }
      }

      &.right {
        background: #ECF2F0;
        padding: $margin-xs;
        border-radius: $radius;
        p{
          font-family: 'Manrope-SemiBold';
          color: $text-color;
          &:first-child{
            text-transform: capitalize;
          }
          &:last-child{
            &.authorized{
              color: $primary-color;
            }
            &:not(.authorized){
              color: $warn;
            }
          }
        }
        &:not(:last-child) {
          margin-bottom: $margin-xs;
        }
      }
    }
  }

  button{
    width: auto;
  }
}
