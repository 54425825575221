@import "../../../styles/_variables.scss";

.section-agencies {

  .header{
    display: flex;
    align-items: center;
    margin: $margin-xs 0 $margin-xs 0;
    h5{
      margin: 0 0 0 0 !important;
    }
    button{
      margin-left: auto;
      width: auto;
      >span{
        margin-left: 0 !important;
        svg{
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .add-agencies{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-sm;

    .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 23px;
      color: $primary-color;
      font-size: 16px;
    }

    button {
      width: auto;
    }
    div:first-child{
      width: 100%;
    }
  }

  .agencies{
    .agency{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $light-grey;
      height: 31px;
      padding: 0 $margin-xs;
      border-radius: $radius;

      .info{
        p{
          display: inline-block;
          margin: 0;
          &:first-child{
            font-family: 'Manrope-Bold';
          }
          &:not(:first-child){
            margin-left: $margin-xs;
          }
        }
      }

      &:not(:last-child) {
        margin: 0 0 $margin-xs 0;
      }
      &:last-child {
        margin: 0 0 $margin-sm 0;
      }
      svg{
        position: relative;
        top: 1px;
        color: $warn;
        font-size: $medium;
        cursor: pointer;
      }
    }
  }
   
}