@import "../../../styles/_variables.scss";

.section-tags {
  .create-tag{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;
    button {
      width: auto;
      margin-left: $margin-sm;
    }
    div:first-child{
      width: 100%;
    }
  }
  .tags {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $margin-xs;
    margin-bottom: $margin-sm;
    @media screen and (min-width: 500px) {
      grid-template-columns: repeat(3, 1fr);
    }
    .container-field {
      display: flex;
      align-items: center;
      background-color: $light-grey;
      border-radius: $radius;
      input {
        min-width: inherit;
      }
      .delete, .edit{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 31px;
        padding: 0 3px;
      }
      .delete{ 
        border-left: solid 1px rgba(113, 122, 151, 0.2);
        color: $warn;
        cursor: pointer;
       }
      .edit{ color: $primary-color; }
      svg{
        margin:0 3px;

      }
    }
  }
   
}