@import '../../styles/_variables.scss';

.loading{
  overflow: hidden;
  border-radius: 8px;
}

.tile-shortcut{
  display: flex;
  width: 100%;
  height: 105px;
  padding: $margin-sm;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);

  @media screen and (min-width: $sm-screen) {
    width: 300px;
  }

  .col{
    display: flex;
    flex: 1;
    &:first-child{
      align-items: center;
      padding-right: $margin-sm;
      >div{
        display: flex;
        align-items: center;
        .icon{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 65px;
          height: 65px;
          border-radius: 50%;
          background-color: $light-grey;
          margin-right: $margin-sm;
          img{
            width: 40px;
          }
        }

        h4{
          font-size: $medium;
          margin: 0;
        }
      }
    }
    &:nth-child(2){
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      p{
        font-size: $small;
        margin: 0;
      }
    }
  }


  button{
    font-size: 30px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    padding: 0;
  }
}
