@import '../../../styles/_variables.scss';

.row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: $margin-sm;
  border-radius: 8px;

  &.selected{
    background-color: $secondary-color;
    p {
      color: $white;
    }
  }
}