.overlay-modal{
  position: fixed;
  z-index: 1002;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal{
  position: fixed;
  z-index: 1003;
  width: 100%;
  max-width: 400px;
  padding: 30px;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  
}
