@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.navbar{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background: $white;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  padding: 0 $margin-xs 0 $margin-sm;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);

  @media screen and (max-width: $sm-screen) {
    z-index: 2000;
  }

  @media screen and (min-width: $sm-screen) {
    border-radius: 0 0 22px 22px;
  }


  // ON TABLET
  @media screen and (max-width: $md-screen) {
    height: 80px;
    transition: all .4s ease-in-out;
    &.opened{
      color: $white;
      background-color: $text-color;
      height: 100%;
      border-radius: 0;
      h1, li, a, p{
        color: $white;
      }
    }
  }

  @media screen and (min-width: $md-screen) {
    height: 50px;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo{
    display: none;
    img {
      height: 50px;
    }
    @media screen and (min-width: $md-screen) {
      display: block;
    }
  }

  .header-tablet{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    min-height: 80px;
    width: 100%;
    @media screen and (min-width: $md-screen) {
      display: none;
    }
    h1{
      font-size: $h3;
      margin: 0;
    }
  }

  ul{
    @include container();
    padding: $margin-lg 0 0 $margin-xl;
    display: flex;
    flex: 1;
    flex-direction: column;
    @media screen and (min-width: $md-screen) {
      padding: 0;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }
    li{
      list-style: none;
      @media screen and (min-width: $md-screen) {
        padding: 0 $margin-sm;
      }
      a{
        position: relative;
        display: block;
        text-decoration: none;
        line-height: 50px;
        color: $text-light; 
        font-size: $h3;
        font-family: 'Manrope-Bold';
        @media screen and (min-width: $md-screen) {
          font-family: 'Manrope-Regular';
          font-size: $regular;
        }
        &:after{
          content: '';
          position: absolute;
          display: block;
          top: 50%;
          left: calc(-1 * $margin-sm);
          width: 90px;
          height: 3px;
          border-radius: 2px;
          background-color: $primary-color;
          transform: translateX(-100%) scale(0, 1);
          @media screen and (min-width: $md-screen) {
            width: 100%;
            transform: scale(0, 1);
            transform-origin: center;
            top: inherit;
            bottom: 0;
            left: 0;
          }
        }

        &.active:after{
          transform: translateX(-100%) scale(1, 1);
        }

        @media screen and (min-width: $md-screen) {
          &.active{
            color: $text-color;
            &:after{
              transform: scale(1, 1);
            }
          }
        }
      }
    }
  }

  .profile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $margin-xs;
    padding-bottom: $margin-md;
    width: 100%;
    @media screen and (min-width: $md-screen) {
      padding-bottom: 0;
      width: auto;
      justify-content: center;
    }
    p{
      display: inline-flex;
      font-family: 'Manrope-Medium';
      white-space: nowrap;
      margin-right: $margin-xs;
    }
    .initials{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-right: $margin-xs;
      border-radius: 50%;
      background-color: $primary-color;
      >span{
        position: relative;
        top: -1px;
        font-family: 'Manrope-Bold';
        font-size: $small;
        color: $white;
      }
    }

    .logout{
      position: relative;
      top: 2px;
      font-size: 28px;
      cursor: pointer;
      @media screen and (min-width: $md-screen) {
        font-size: 18px;
      }
      svg{
        color: $primary-color;
      }
    }

  }

}