@import "../../../styles/_variables.scss";

.section-permissions {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-family: 'Manrope-SemiBold';
      font-weight: normal;
      font-size: $small;
      color: $primary-color;
      text-decoration: underline;
      margin: 0;
      >span{
        color: $text-color;
      }
    }
  }

  .rights{
    padding: 0;
    margin: 0;
    li{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $light-grey;
      border-radius: $radius;
      padding: 3px 3px 3px $margin-xs;
      &:not(:last-child) {
        margin-bottom: $margin-xs;
      }

      p{
        font-family: 'Manrope-SemiBold';
        font-weight: normal;
        margin: 0;
        &:first-child{
          text-transform: capitalize;
        }
      }

      .status{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.editable {
          p{
            background-color: #CED8EF;
            cursor: pointer;
          }
          &.authorized p:first-child{
            background-color: $primary-color;
            color: $white;
          }
          &:not(.authorized) p:last-child{
            background-color: $warn;
            color: $white;
          }
        }

        &:not(.editable){
          .allow{
            color: $primary-color;
          }
          .notallow{
            color: $warn;
          }
        }

        p{
          border-radius: $radius;
          padding: 3px $margin-xs;
          font-size: $small;
          transition: all .4s ease-in-out;
          &:first-child{
            margin-right: 3px;
          }
        }
      }

    }
  }

}
