@import '../../../styles/_variables.scss';
@import '../form-mixin.scss';
.date-picker{

  label{
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.container-input{
      margin-top: 10px;
    }
  }

  .container-input{
    position: relative;
    width: 100%;
    
    &.primary {
      @include primary;
    }
    &.login {
      @include login;
    }

    &.discreet {
      input {
        display: inline-block;
        text-transform: capitalize;
        caret-color: transparent;
        margin-right: $margin-xs;
        &::placeholder {
          text-transform: none;
        }
      }
    }

    .container-icon{
      display: block;
      position: absolute;
      top:0;
      right:0;
      height: 31px;
      width: 31px;
      pointer-events: none;
      >svg{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        color: $primary-color;
      }
    }

    >div{
      position: relative;
      width: 100%;
    }
  }

  .day{
    color : black;

    &.selected{
      background-color: $primary-color;
      color : white;
    }

    &:not(.selected){
      background-color: transparent;
    }
  }

  input {
    @include input;
  }

}
