@import "../../../styles/_variables.scss";

.section-closings {
  .create-closing {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: $margin-md;
    >button {
      width: auto;
      margin-left: $margin-sm;
    }
    > div:first-child {
      flex: 1;
      margin-bottom: $margin-xs;
    }
  }

  .timing {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $margin-xs;
    width: 100%;
    input {
      min-width: auto !important;
    }
  }

  .closings {
    grid-gap: $margin-xs;
    margin-bottom: $margin-sm;
    .container-field {
      &:not(:last-child) {
        margin-bottom: $margin-sm;
      }
      > div:first-child {
        display: flex;
        justify-content: flex-start;
        flex: 1;
        align-items: center;
        background-color: $light-grey;
        border-radius: $radius;
        > div:first-child {
          flex: 1;
        }
        input {
          width: 100%;
          min-width: inherit;
        }
        .delete,
        .edit {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 31px;
          padding: 0 3px;
          cursor: pointer;
        }
        .delete {
          border-left: solid 1px rgba(113, 122, 151, 0.2);
          color: $warn;
          cursor: pointer;
        }
        .edit {
          color: $primary-color;
        }
        svg {
          margin: 0 3px;
        }
      }
      .timing {
        margin-top: $margin-xs;
      }
    }
  }
}
