@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  max-width: 530px;
  width: 100%;
  background: $white;
  height: 100%;
  max-height: 100%;

  .form {
    flex: 1;
    overflow-y: auto;
    padding: $margin-md;

    .close-mobile {
      cursor: pointer;
      font-family: 'Manrope-Bold';
      font-size: $medium;
      margin: 0 0 $margin-sm;
      color: $primary-color;
      width: 100%;
      @include remove-highlight();
      @include remove-tap-highlight();
      svg{
        position: relative;
        top: 6px;
        margin-right: $margin-xs;
      }
    }

    .agency-info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: solid 1px rgba(113, 122, 151, 0.5);

      .col {
        margin: 0 0 $margin-sm 0;

        .header{
          margin-bottom: $margin-md;
          .entity{
            font-size: $regular;
            font-family: 'Manrope-Bold';
            margin: 0 0 3px 0;
          }

          h3 {
            font-family: 'Manrope-Bold';
            font-size: $h4;
            margin: 0 0 3px 0;
          }

          h5 {
            margin: 0 0 5px 0;
          }

          .localisation{
            font-family: 'Manrope-SemiBold';
            font-size: $regular;
            margin: 0;
          }

        }

        .more-info{

          p{
            font-size: $regular;
            margin: 0;
          }

          .casesCount {
            font-family: 'Manrope-Bold';
            color: $primary-color;
            margin-bottom: $margin-md;
            img{
              position: relative;
              top: 2px;
              margin-right: 3px;
            }
          }

          .mode, .contact{
            font-family: 'Manrope-Bold';
            span{
              @include tag('basic');
              font-family: 'Manrope-Regular';
            }
          }

          h5 {
            color: $primary-color;
            margin: 0 0 $margin-xs 0;
            svg {
              position: relative;
              top: 3px;
              margin-right: 5px;
            }
          }

          .contact {
            margin-bottom: $margin-xs;
          }

          .openingTimes{
            font-family: 'Manrope-Bold';
            vertical-align: top;
            margin-top: 5px;
            margin-bottom: $margin-xs;
            span{
              display: block;
              margin-top: 3px;
              @include tag('basic');
              font-family: 'Manrope-Regular';
              vertical-align: top;
            }
          }
        }

        &:nth-child(2) {
          flex: 1;
          @media screen and (min-width: $sm-screen) {
            margin-right: $margin-sm;
          }
        }

        button {
          width: inherit;
        }
  
        .container-field:not(:last-child) {
          margin: 0 0 $margin-sm 0;
        }
        .message-error {
          color: $warn;
          font-size: 12px;
        }

      }

      .errorMessage{
        flex: 1;
        min-width: 100%;
        background-color: rgba(245, 91, 93, 0.1);
        border-radius: $radius;
        margin-bottom: $margin-sm;
        p{
          text-align: center;
        }
      }

    }

    .informations{
      p{
        text-align: center;
      }
      img {
        display: block;
        margin: $margin-lg auto;
        width: 100%;
        max-width: 300px;
      }
    }

    section h5{
      margin-top: $margin-md;
    }

    section:not(:last-child) {
      border-bottom: solid 1px rgba(113, 122, 151, 0.5);
    }
  }

  .footer {
    display: flex;
    align-items: center;
    border-top: solid 1px rgba(113, 122, 151, 0.5);
    padding: $margin-sm $margin-md $margin-sm $margin-md;

    .delete{
      margin: 0;
      font-family: 'Manrope-SemiBold';
      color: $warn;
      cursor: pointer;

      span.loader{
        position: relative;
        top: 4px;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin: 1px 5px 1px 0;
        >div{
          top: 0; left: 0;
        }
      }

      span:not(.loader){
        svg{
          position: relative;
          top: 5px;
          margin-right: 3px;
        }
      }


    }

    button {
      display: block;
      width: auto;
      margin-left: auto;
    }
  }
}
