@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.permission {
  background-color: $white;
  border-radius: 8px;
  padding: $margin-xs;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  &:not(:last-child) {
    margin-bottom: $margin-md;
  }
  .header {
    transition: all 0.3s ease-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-md;
    border-bottom: 1px solid rgba(113, 122, 151, 0);
    p {
      font-family: 'Manrope-Bold';
      margin: 0;
    }

    .toggleEdit{
      position: relative;
      top: 3px;
      color: $primary-color;
      font-size: 20px;
      margin-right: 4px;
      cursor: pointer;
      padding: 0 3px;
    }

    >span:last-child{
      color: $primary-color;
      font-size: 30px;
      margin-left: auto;
      cursor: pointer;
    }

    .container-field{
      margin: 3px 0 $margin-xs 0;
    }

  }
  p {
    font-family: 'Manrope-SemiBold';
    font-size: $regular;
  }


  &.open {
    .header {
      border-color:rgba(113, 122, 151, .3);
    }
    .rights{
      max-height: 450px;
    }
  }

  &:not(.open) {
    .header {
      margin-bottom: 0;
    }
    .rights {
      max-height: 0px;
      overflow: hidden;
    }
  }

  .rights {
    transition: all 0.5s ease-out;
    ul {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $margin-xs;
        border-radius: 5px;
        background-color: $light-grey;
        p {
          text-transform: capitalize;
          margin: 0;
        }
        &:not(:last-child){
          margin-bottom: $margin-sm;
        }
        .toggle-right {
          display: flex;
          align-items: center;
          justify-content: center;
          &.editable {
            p{
              background-color: #CED8EF;
              cursor: pointer;
            }
            &.authorized .allow{
              background-color: $primary-color;
              color: $white;
            }
            &:not(.authorized) .notallow{
              background-color: $warn;
              color: $white;
            }
          }
        }
        .toggle-right p {
          margin: 0;
          border-radius: $radius;
          padding: 3px $margin-xs;
          font-size: $small;
          transition: all .2s ease-in-out;
          &:first-child {
            text-transform: capitalize;
          }
          &:nth-child(2) {
            margin-left: $margin-xs;
          }
          &.allow {
            color: $primary-color;
          }
  
          &.notallow {
            color: $warn;
          }
        }
      }
    }
    .footer{
      margin-top: $margin-sm;
    }
  }

  .errorMessage{
    flex: 1;
    min-width: 100%;
    background-color: rgba(245, 91, 93, 0.1);
    border-radius: $radius;
    margin: $margin-sm 0;
    padding: 3px 0;
    p{
      text-align: center;
    }
  }

  .footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: $margin-xs;
    .delete{
      width: 100%;
      margin: 0 0 $margin-sm;
      font-family: 'Manrope-SemiBold';
      color: $warn;
      cursor: pointer;

      @media screen and (min-width: 500px) {
        width: auto;
        margin-bottom: 0;
      }

      span.loader{
        position: relative;
        top: 4px;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin: 1px 5px 1px 0;
        >div{
          top: 0; left: 0;
        }
      }

      span:not(.loader){
        svg{
          position: relative;
          top: 4px;
          margin-right: 3px;
        }
      }


    }
    button{
      margin-left: auto;
    }
  }

}