@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.members{
  .container{
    @include container();
    .header{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      h1{
        display: none;
        margin-top: 0;
        @media screen and (min-width: $sm-screen) {
          display: block;
        }
      }

      .headerAdmin{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: $margin-md;
        @media screen and (min-width: $sm-screen) {
          flex-direction: row;
        }
        >div:first-child{
          margin-right: 100px;
          margin-bottom: $margin-md;
          @media screen and (min-width: $sm-screen) {
            margin-bottom: 0;
          }
          h1{
            font-size: $h2;
            margin: $margin-xs 0 0 0;
          }
          h3{
            font-family: 'Manrope-Bold';
            display: inline-block;
            font-size: $h4;
            margin: 0;
          }
          .selectAgency{
            position: relative;

            h3{
              padding-bottom: 3px;
              border-bottom: 2px solid rgba(113, 122, 151, 0.2);
              cursor: pointer;
              @include remove-highlight();
              svg{
                position: relative;
                top: 4px;
                margin-left: $margin-xs;
              }
            }

            &:not(.opened) .opts{
              display: none;
            }

            .opts{
              position: absolute;
              z-index: 2;
              background-color: $white;
              border-radius: 5px;
              min-width: 100%;
              bottom: -10px;
              transform: translateY(100%);
              box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);

              ul {
                margin: 0;
                padding: 0;
                li{
                  list-style: none;
                  cursor: pointer;
                  white-space: nowrap;
                  padding: $margin-xs $margin-sm;
                  &:not(:last-child) {
                    border-bottom: 1px solid #F4F4F4;;
                  }
                  >span {
                    font-family: 'Manrope-Bold';
                  }
                }
              }
            }
          }
        }
      }

      .right{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
        @media screen and (min-width: $sm-screen) {
          width: auto;
          flex-direction: row;
          margin-top: 5px;
        }
        .search{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $radius;
          background-color: $white;
          box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
          width: 100%;
          @media screen and (min-width: $sm-screen) {
            width: auto;
          }
          input{
            display: block;
            border: none;
            outline: none;
            border-radius: 0px;
            box-sizing: border-box;
            background-color: transparent;
            padding: $margin-sm $margin-xs;
            font-family: 'Manrope-SemiBold';
            font-size: $regular;
            color: $text-color;
            width: 100%;
            @media screen and (min-width: $sm-screen) {
              min-width: 220px;
              padding: 7px $margin-xs;
            }
            &::placeholder {
              font-family: 'Manrope-Regular';
              color : #a3a3a3;
            }
          }

          >svg{
            color: $primary-color;
            margin-right: $margin-xs;
            font-size: 20px;
            @media screen and (min-width: $sm-screen) {
              font-size: $regular;
            }
          }

        }

        .search + a{
          margin: 0 0 $margin-sm;
          width: 100%;
          @media screen and (max-width: $sm-screen) {
            button {
              max-width: 100%;
              padding: $margin-sm;
            }
          }
          @media screen and (min-width: $sm-screen) {
            margin: 0 0 0 $margin-sm;
            width: auto;
          }
        }

      }
    }
  }
}