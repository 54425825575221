@import './_variables.scss';
@import './_mixins.scss';

html,
body,
#root{
  height: 100%;
  min-height: 100%;
  background-color: $bg-color;
}

body {
  margin: 0;
  font-family: 'Manrope-Regular', sans-serif;
  font-size: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

*{
  box-sizing: border-box;
}

h1,h2 {
  font-family: 'Manrope-Bold', sans-serif;
  font-weight: normal;
  color: $text-color;
}

h3,h4 {
  font-family: 'Manrope-SemiBold', sans-serif;
  font-weight: normal;
  color: $text-color;
}

h1{
  font-size: $h1;
}

h2{
  font-size: $h2;
}

h3{
  font-size: $h3;
}

h4{
  font-size: $h4;
}

h5{
  font-family: 'Manrope-Bold', sans-serif;
  font-weight: normal;
  font-size: $regular;
  margin-bottom: $margin-sm;
}


p, a, li, input, textarea{
  font-family: 'Manrope-Regular', sans-serif;
  color: $text-color;
  font-size: $regular;
  &.bold{
    font-family: 'Manrope-SemiBold', sans-serif;
  }
  &.small{
    font-size: $small;
  }
}

a{
  text-underline-offset: 2px;
  @include remove-tap-highlight();
}

button{
  align-items: center;
  justify-content: center;
  font-family: 'Manrope-SemiBold', sans-serif;
  font-size: $regular;
  background-color: $secondary-color;
  padding: 4px 10px;
  color : white;
  border: solid 1px $secondary-color;
  border-radius: 8px;
  text-align: center;
  max-width: 200px;
  width: 100%;
  // height: 33px;
  white-space: nowrap;
  cursor: pointer;
  @include remove-tap-highlight();
  span{
    vertical-align: middle;
  }

  .icon{
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 0px;

    &.loader>div{
      position:initial;
    }

    svg{
      position: relative;
      top:-50%;
      vertical-align: middle;
    }
  }

  .icon:not(.loader):first-child{
    margin-left: -5px;
  }

  span:first-child:not(.icon),
  .icon+span{
    position: relative;
    top: -1px;
  }

  span+.icon,
  .icon+span{
    margin-left: 5px;
  }

  &.secondary{
    background-color: $white;
    border: solid 1px $text-color;
    color : $text-color;
  }

  &.disabled{
    opacity: 0.7;
  }

}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px transparent inset !important;
}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-on-mobile{
  display: none;
  @media screen and (min-width: $sm-screen) {
    display: block;
  }
}

.only-on-mobile{
  display: block;
  @media screen and (min-width: $sm-screen) {
    display: none;
  }
}