@import '../../../styles/_variables.scss';

.container-custom-date-picker{

  .react-datepicker{
    font-family: 'Manrope-Regular';
  }

  .react-datepicker-popper{
    .react-datepicker {
      box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
      border-color: $medium-grey;
    }
  }

  .react-datepicker__triangle{
    // display: none;
    top: 0px !important;
    z-index: 999;
    &:before{
      border-bottom-color: $medium-grey !important;
    }
    &:after{
      border-bottom-color: $white !important;
    }
  }

  .react-datepicker__navigation-icon{
    &:before,
    &:after{
      border-color: $primary-color;
    }
  }

  .react-datepicker__header{
    background-color: white;
    border-bottom: 1px solid #d2d2d2;
   

    .react-datepicker__current-month{
      position: relative;
      top: -1px;
      line-height: 20px;
      font-family: 'Manrope-ExtraBold';
      color : $primary-color;
    }
  }

  .react-datepicker__day--today{
    font-family: 'Manrope-ExtraBold';
    color: $primary-color;
  }

}
