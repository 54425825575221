@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.home{
  .container{
    @include container();
    .shorcuts{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0;
      margin: 0 0 $margin-md 0;
      li{
        vertical-align: top;
        display: inline-block;
        width: 100%;
        &:not(:last-child) {
          margin: 0 0 $margin-sm 0;
        }
      }
      @media screen and (min-width: $sm-screen) {
        margin: $margin-lg 0;
        li {
          width: 300px;
          margin: 0;
          &:not(:last-child) {
            margin: 0 $margin-sm 0 0;
          }
        }
      }
    }
    .titleEntity{
      margin: 0 0 $margin-sm 0;
      @media screen and (min-width: $sm-screen) {
        margin: 0;
      }
    }
  }
}