@import "../../../styles/_variables.scss";

.section-members {
  .add-members{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-sm;

    .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 23px;
      color: $primary-color;
      font-size: 16px;
    }

    button {
      width: auto;
      margin-left: $margin-sm;
    }
    div:first-child{
      width: 100%;
    }
  }

  .members{
    margin: 0 0 $margin-sm 0;
    .member{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $light-grey;
      height: 31px;
      padding: 0 $margin-xs 0 5px;
      border-radius: $radius;

      p{ margin:  0;}

      .profile{
        display: flex;
        align-items: center;
        .initial{
          display: block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: $primary-color;
          text-transform: uppercase;
          margin-right: 5px;
          p {
            font-family: 'Manrope-SemiBold';
            color: $white;
            font-size: $small;
            text-align: center;
            line-height: 25px;
          }
        }
      }

      &:not(:last-child) {
        margin: 0 0 $margin-xs 0;
      }
      svg{
        position: relative;
        top: 1px;
        color: $warn;
        font-size: $medium;
        cursor: pointer;
      }
    }
  }
}