@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }
  
  input{
    @include input();
  }
}
