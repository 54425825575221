@import "../../styles/_variables.scss";

.layout{
  flex: 1;
  max-height: calc(100% - 50px);
  overflow: auto;
  @media screen and (max-width: $md-screen) {
    transition: all .4s ease-in-out;
    height: calc(100% - 80px);
    &.nav-opened{
     height: 0;
    }
  }
}