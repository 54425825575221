$bg-color: #FAFBFF;
$white: #FFFFFF;
$primary-color: #6AA287;
$primary-light-color: rgba(106, 161, 135, 0.3);
$secondary-color: #49517B;
$third-color: #B02E38; 
$warn: #F55B5D;
$light-grey: #F4F7FE;
$medium-grey: #DEE0EA;

$text-light: #717A97;
$text-color: #1B2559;

$xl-screen: 1200px;
$lg-screen: 1024px;
$md-screen: 900px;
$sm-screen: 686px;
$xs-screen: 576px;

$margin-xl : 50px;
$margin-lg : 30px;
$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;

$h1: 28px;
$h2: 26px;
$h3: 24px;
$h4: 20px;
$medium: 18px;
$regular: 14px;
$small: 12px;

$radius: 5px;

@font-face {
	font-family: "Manrope-ExtraBold";
	src: url("../assets/fonts/Manrope-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Manrope-Bold";
	src: url("../assets/fonts/Manrope-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Manrope-SemiBold";
	src: url("../assets/fonts/Manrope-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Manrope-Medium";
	src: url("../assets/fonts/Manrope-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Manrope-Regular";
	src: url("../assets/fonts/Manrope-Regular.ttf") format("truetype");
}