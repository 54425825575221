@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  max-width: 530px;
  width: 100%;
  background: $white;
  height: 100%;
  max-height: 100%;

  .form {
    flex: 1;
    overflow-y: auto;
    padding: $margin-md;

    .close-mobile {
      cursor: pointer;
      font-family: 'Manrope-Bold';
      font-size: $medium;
      margin: 0 0 $margin-sm;
      color: $primary-color;
      width: 100%;
      @include remove-highlight();
      @include remove-tap-highlight();
      svg{
        position: relative;
        top: 6px;
        margin-right: $margin-xs;
      }
    }

    .member-info {
      display: flex;
      flex-wrap: wrap;
      .profile {
        font-family: "Manrope-SemiBold";
        p {
          font-family: "Manrope-SemiBold";
        }
        
        .role {
          p {
            margin: 0;
          }
          &.Member {
            @include tag("basic");
          }
          &.Owner {
            @include tag("light-primary");
          }
          &.Admin {
            @include tag("warn");
            p {
              color: $white;
            }
          }
        }

        .mobileHeader {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: $margin-md;
          @media screen and (min-width: $sm-screen) {
            display: none;
          }
        }

        .header {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: $margin-md;
          h3 {
            margin: 0;
          }
          .job {
            width: 100%;
            margin: 0;
          }
          .role{
            display: none;
            margin-left: $margin-sm;
            @media screen and (min-width: $sm-screen) {
              display: inline-block;
            }
          }
        }

        .more-info {
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 420px) {
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              margin: 0;
              @media screen and (max-width: 420px) {
                line-height: 30px;
              }
            }
            &:first-child {
              padding-right: 10px;
            }
            &:nth-child(2) {
              @media screen and (min-width: 420px) {
                border-left: solid 1px rgba(113, 122, 151, 0.5);
                padding-left: 10px;
              }
            }
          }
          svg + p {
            width: 100%;
            margin-left: $margin-xs;
          }
          svg {
            color: $primary-color;
            font-size: 20px;
          }
        }
      }

      .container-field:not(:last-child) {
        margin: 0 0 $margin-sm 0;
      }

      .col {
        margin: 0 0 $margin-sm 0;

        &:first-child {
          flex: 1;
          @media screen and (min-width: $sm-screen) {
            margin-right: $margin-sm;
          }
        }

        h3 {
          font-size: $medium;
          margin: $margin-xs 0 0 0;
        }

        button {
          width: inherit;
        }

        .message-error {
          color: $warn;
          font-size: 12px;
        }
      }

      .details-fields {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .row {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
  
          .col {
            width: 100%;
            @media screen and (min-width: $sm-screen) {
              width: 50%;
              &:nth-child(2) {
                padding-right: $margin-xs;
              }
              &:last-child {
                padding-left: $margin-xs;
                border-left: solid 1px rgba(113, 122, 151, 0.5);
              }
            }

            label {
              display: block;
              font-family: "Manrope-Bold", sans-serif;
              font-size: $regular;
              line-height: 17px;
              color: $text-color;
              margin-bottom: $margin-xs;
            }
  
            .container-field {
              display: flex;
              align-items: center;
              justify-content: space-between;
              svg + div {
                width: 100%;
                margin-left: $margin-xs;
              }
              svg {
                color: $primary-color;
                font-size: 20px;
              }
              input {
                min-width: auto;
                width: 100%;
              }
            }

            .containerError {
              flex: 1;
              width: 100%;
            }

          }
        }
      }

      .errorMessage {
        flex: 1;
        background-color: rgba(245, 91, 93, 0.1);
        border-radius: $radius;
        margin-bottom: $margin-sm;
        p {
          text-align: center;
        }
      }
    }

    section h5 {
      margin-top: $margin-md;
    }

    section:not(:last-child) {
      border-bottom: solid 1px rgba(113, 122, 151, 0.5);
    }
  }

  .footer {
    display: flex;
    align-items: center;
    border-top: solid 1px rgba(113, 122, 151, 0.5);
    padding: $margin-sm $margin-md $margin-sm $margin-md;

    .delete {
      margin: 0;
      font-family: "Manrope-SemiBold";
      color: $warn;
      cursor: pointer;

      span.loader {
        position: relative;
        top: 4px;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin: 1px 5px 1px 0;
        > div {
          top: 0;
          left: 0;
        }
      }

      span:not(.loader) {
        svg {
          position: relative;
          top: 5px;
          margin-right: 3px;
        }
      }
    }

    button {
      display: block;
      width: auto;
      margin-left: auto;
    }
  }
}
