@import '../../../styles/variables';

.signin-form{
  max-width: 900px;
  margin: 0 auto;

  .form{
    margin-bottom: $margin-lg;
    .container-field{
      margin: 0 0 15px 0;
    }
    .message-error{
      color : $warn;
      font-size: 12px;
    }
  }

  .container-link{
    margin-bottom: $margin-md;
  }

  .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
