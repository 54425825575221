@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-textarea{
  margin: 5px 0px 0px 0px;

  label{
    @include label;
  }

  &:not(.inline):not(.primary) {
    label {
      position: absolute;
      left: 7px;
      background-color: $bg-color;
      padding: 0 4px;
      will-change: transform;
      transition: transform 0.2s ease-in-out;
      transform: translateY(17px);
    }
    &.active label{
      transform: translateY(-50%);
    }
  }

  &.inline{
    @include inline;
  }

  &:not(.inline){
    label+.container{
      margin-top: 10px;
    }
  }

  &.disabled{
    pointer-events: none;
    opacity: .5;
  }

  .input {
    @include input;

    padding-top: 10px;
    padding-bottom: 10px;
    resize: none;

    &.disabled{
      pointer-events: none;
    }
  }

  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }

  .container{
    width: 100%;
    min-width: 250px;

    .count-characters{
      text-align: right;
      font-size: 12px;
      margin: 0;
      padding: 0;
    }

  }





}
