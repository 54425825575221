@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.profiles{
  .container{
    @include container();
    .header{
      display: flex;
      justify-content: space-between;
      h1{
        display: none;
        margin-top: 0;
        @media screen and (min-width: $sm-screen) {
          display: block;
        }
      }
      >span{
        margin: 0 0 $margin-sm;
        width: 100%;
        @media screen and (max-width: $sm-screen) {
          button {
            max-width: 100%;
            padding: $margin-sm;
          }
        }
        @media screen and (min-width: $sm-screen) {
          margin: 0 0 0 $margin-sm;
          width: auto;
        }
      }
    }

  }
}